.main-page__forum-button {
  display: flex;
  justify-content: center;
  margin: 0 0 80px;

  a {
    background: #7AA8EE;
    border-radius: 10px;
    color: #ffffff;
    width: fit-content;
    padding: 25px 45px;
    transition: all .2s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #659ef5;
    }

    @media (max-width: 768px) {
      padding: 7px 30px;
    }
  }

  @media (max-width: 768px) {
    margin: 40px 0;
  }
}