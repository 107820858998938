.flex {
  display: flex;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}
