@import "./__img/header-search-result-item__img.scss";
@import "./__link/header-search-result-item__link.scss";

.header-search-result-item {
  transition: all .1s ease-in-out;

  &:hover {
    background: #e1ebff;
  }
}
