.elem-page__subtitle {
  margin-bottom: 20px;
  width: 80%;
  white-space: pre-line;

  @media (max-width: 580px) {
    font-size: 0.9rem;
    line-height: 1rem;
    margin-bottom: 20px;
    width: auto;
  }
}
