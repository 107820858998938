@import "./__burger/sidebar__burger.scss";
@import "./__bg/sidebar__bg.scss";
@import "./__content/sidebar__content.scss";
@import "./__nav-list/sidebar__nav-list.scss";

.sidebar {
  pointer-events: auto;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
