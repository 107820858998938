.header-user-menu__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: $dark-blue;
  color: #ffffff;
  cursor: pointer;
}
