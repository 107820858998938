.submit-form__close-button {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 14px;
  height: 14px;

  button {
    width: 14px;
    height: 14px;
  }

  svg {
    transition: fill .2s;
  }

  &:hover {
    svg {
      fill: $gray6;
    }
  }

  @media (max-width: 520px) {
    top: 15px;
    right: 15px;
  }
}
