.input {
  position: relative;
  width: 100%;

  input, textarea {
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    line-height: 1.3rem;
    border: 1px solid $gray2;
    border-radius: 4px;
    transition: all .2s;
    outline: none;
    color: $black;
    text-overflow: ellipsis;

    &::placeholder {
      color: $gray3;
    }

    &:hover {
      border-color: #666666;
    }

    &:focus {
      background: $light-blue;
      border-color: $blue;
    }
  }

  textarea {
    min-height: 120px;
    resize: none;
  }

  &.activeDropdown {
    input, textarea {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.disabled {
    input, textarea {
      cursor: default;
      background: $gray;
      border-color: $gray1;

      &:hover {
        border-color: $gray1;
      }

      &:focus {
        background: $gray;
        border-color: $gray1;
      }
    }
  }
}

.input_success {
  input, textarea {
    border-color: $green;
  }
}

.input_invalid {
  input, textarea {
    border-color: $red;

    &:focus {
      background: $light-red;
      border-color: $red;
    }
  }
}

.comments-block {
  textarea {
    min-height: 80px;
    border-radius: 10px;
    border: none;
  }
}

@media (max-width: 480px) {
  .input {
    input, textarea {
      padding: 7px 10px;
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }
}
