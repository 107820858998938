.submit-form__title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 30px 0;
  text-align: center;

  @media (max-width: 420px) {
    margin: 0 0 15px 0;
  }
}
