.button {
  display: block;
  border: 1px solid transparent;
  background-color: $blue;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  border-radius: 7px;
  transition: background .2s;
  white-space: nowrap;

  &:hover {
    background-color: $dark-blue;
    cursor: pointer;
  }

  &.second {
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;
    &:hover {
      background-color: $blue;
      color: #ffffff;
      cursor: pointer;
    }

    &.disabled {
      background-color: transparent;
      border: 1px solid $gray1;
      cursor: default;
      color: $gray1;
      &:hover {
        border: 1px solid $gray1;
        background-color: transparent;
      }
    }
  }

  &.red {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;
    &:hover {
      background-color: $red;
      color: #ffffff;
      cursor: pointer;
    }

    &.disabled {
      background-color: transparent;
      border: 1px solid $gray1;
      cursor: default;
      color: $gray1;
      &:hover {
        border: 1px solid $gray1;
        background-color: transparent;
      }
    }
  }

  &.gray-5 {
    background-color: $gray5;
    &:hover {
      background-color: $gray6;
      cursor: pointer;
    }
  }

  &.large {
    width: 100%;
  }

  &.padding-large {
    padding: 10px 70px;
  }

  &.disabled {
    background-color: $gray1;
    cursor: default;
    border: 1px solid transparent;
    &:hover {
      background-color: $gray1;
    }
  }
}


@media (max-width: 480px) {
  .header-search {
    .button {
      padding: 5px 8px;
    }
  }
}
