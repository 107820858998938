@import "./__container/pagination__container.scss";

.pagination {
  display: flex;

  li {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  a {
    padding: 5px 10px;
    color: $blue;
    border: 1px solid $dark-blue;
    transition: all .2s;
    cursor: pointer;

    &:hover {
      background-color: $blue;
      color: #ffffff;
    }
  }

  .selected {
    a {
      cursor: default;
      background-color: $blue;
      color: #ffffff;
    }
  }
}