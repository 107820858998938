.filters__form-item {
  width: 50%;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1040px) {
    width: 50%;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
