.footer__logo {
  .text {
    font-family: 'Ubuntu', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8rem;
    white-space: nowrap;

    @media (max-width: 580px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }

    &:first-child {
      margin-bottom: 3px;
    }
  }
  .text-very-small {
    font-family: 'Ubuntu', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    white-space: nowrap;

    @media (max-width: 580px) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }
}
