@import "./__container/footer__container";
@import "./__logo/footer__logo";
@import "./__info-block/footer__info-block";
@import "./__link-block/footer__link-block";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;

  @media (max-width: 768px) {
    height: 228px;
  }

  @media (max-width: 580px) {
    height: auto;
    padding: 15px 0;
  }
}
