.submit-form__item {
  width: 100%;
  margin: 0 0 15px 0;

  &:last-child {
    margin: 0;
  }

  &.invalid {
    padding-bottom: 20px;
  }
}
