.cards-list__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1180px) {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-bottom: 30px;
  }
}
