.articles-item__title {
  font-size: 1.1rem;
  line-height: 1.2rem;
}

.articles-item__subtitle {
  font-size: 0.9rem;
  line-height: 1rem;
  color: $gray5;
  margin-bottom: 5px;
}

.articles-item__text-wrapper {
  padding: 0.8rem 1rem;
}