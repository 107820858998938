.header__container {
  background: #ECF4FF;
  position: sticky;
  top: 0;
  box-shadow: 1px 4px 4px rgb(111 168 244 / 35%);
  z-index: 2;

  @media (max-width: 580px) {
    position: relative;
    z-index: 1000;
  }
}
