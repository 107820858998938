.sidebar__bg {
  position: absolute;
  top: 0;
  right: 0;
  transition: background-color 0.25s;
  -webkit-transition: background-color 0.25s;

  &.active {
    background-color: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
  }
}