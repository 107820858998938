.chevron-icon {
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #EDF4FE;
  border-radius: 50%;
  transition: transform .2s ease;
  cursor: pointer;

  &__open {
    transform: rotate(-90deg);
  }

  &__closed {
    transform: rotate(90deg);
  }

}