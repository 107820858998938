.select__header {
  padding: 10px 15px;
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray3;
  transition: all .2s ease;
  border: 1px solid $gray2;
  border-radius: 4px;
  cursor: pointer;

  svg {
    transition: all .2s ease;
  }

  &:hover {
    border-color: #666666;

    svg {
      stroke: #666666;
    }
  }

  &.focus {
    outline: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    svg {
      transform: rotate(180deg);
    }
  }
}
