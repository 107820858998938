.pdf-sidebar {
  position: fixed;
  width: 320px;
  overflow: auto;
  border-right: #DCE0E3 1px solid;
  padding: 20px 25px;
  transition: all 0.5s;
  top: 162px;
  height: calc(100vh - 160px);
  background-color: $gray3;
  opacity: 1;

  &.hide {
    opacity: 0;
    user-select: none;
    margin-left: -320px;
  }

  @media (max-width: 1024px) {
    width: 250px;
  }

  @media (max-width: 768px) {
    width: 200px;
    top: 80px;
    height: calc(100vh - 80px);
  }

  @media (max-width: 645px) {
    width: 150px;
    padding: 10px 15px;
  }

  @media (max-width: 580px) {
    width: 100%;
    height: 180px;
    z-index: 10;

    &.hide {
      margin-left: 0;
      margin-top: -180px;
    }
  }
}