@charset "UTF-8";

/**
 * Основные стили
 **/

@media print {
  html, body {
    display: none;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@media (max-width: 420px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: 'Roboto', 'PT Root UI', 'Arial', sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

a {
  -webkit-tap-highlight-color: rgba($color: #fff, $alpha: 0);
}

a:visited {
  color: inherit;
}

b {
  font-weight: 700;
}

.container {
  @include min-max(320px, 100%, 0);
}

.wrap {
  @include min-max(0, 1660px, 40px);
  position: relative;
}

@media (max-width: 860px) {
  .wrap {
    @include min-max(0, 1660px, 20px);
    position: relative;
  }
}