.copyright__img {
  width: 64px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 580px) {
    width: 32px;
  }
}
