.error-page__subtitle {
  font-size: 42px;
  margin-bottom: 10px;
}

@media (max-width: 860px) {
  .error-page__subtitle {
    font-size: 1.4rem;
  }
}
