.react-tabs__tab {
  padding: 5px 10px;
  color: #ececec;
  border: 2px solid #7c86a5;
  border-bottom: none;
  bottom: -2px;
  position: relative;
  background: #7c86a5;
  outline: none;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }

  &.first-el {
    border-right: none;
    border-top-left-radius: 4px;
  }

  &.last-el {
    border-top-right-radius: 4px;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 5px;
  }

  @media (max-width: 645px) {
    font-size: 0.5rem;
  }

  @media (max-width: 580px) {
    font-size: 0.9rem;
    padding: 5px 10px;
  }
}

.react-tabs__tab--selected {
  background: #ABB5BE;
}