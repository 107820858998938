.main-page__about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  &-title {
    margin: 50px 0 50px;
    text-align: center;
    font-size: 2.25rem;
    color: #4F4F4F;
  }

  &-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    max-width: 450px;
    text-align: center;

    & p {
      color: #848484;
      margin-bottom: 30px;
    }
  }

  &-button {
    width: 300px;
    color: #FFFFFF;
    background: #6FA8F4;
    -webkit-box-shadow: 0px 4px 4px rgba(111, 168, 244, 0.35), inset 0px 4px 4px rgba(106, 143, 247, 0.1);
    box-shadow: 0px 4px 4px rgba(111, 168, 244, 0.35), inset 0px 4px 4px rgba(106, 143, 247, 0.1);
    border-radius: 10px;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
      background: #6FA8F4;
      -webkit-transform: scale(1.025);
      -ms-transform: scale(1.025);
      transform: scale(1.025);
    }
  }

  @media (max-width: 768px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}