.error-page__content {
    background: #ffffff;
	padding: 100px 80px;
}

@media (max-width: 860px) {
	.error-page__content {
		padding: 0;
	}
}
