.header__burger {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 30px;

  @media (max-width: 860px) {
    display: none;
  }
}
