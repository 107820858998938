@import "./__top/comments-item__top.scss";
@import "./__name/comments-item__name.scss";
@import "./__submit-button/comments-item__submit-button.scss";
@import "./__text/comments-item__text.scss";

.comments-item {
  padding: 25px;
  background: $gray8;
  border-radius: 10px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }
}

.comments-item_form {
  @media (max-width: 580px) {
    padding: 10px;
  }
}
