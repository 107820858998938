@import "./__header/select__header.scss";
@import "./__options/select__options.scss";
@import "./__options-grid/select__options-grid.scss";
@import "./__options-btn/select__options-btn.scss";

.select {
  width: 100%;
  font-size: 0.9rem;
  transition: all .3s ease-in-out;
  color: $black;
  position: relative;

  &.disabled {
    .select__header {
      cursor: default;
      color: $gray2;
      background: $gray;
      border-color: $gray1;

      svg {
        stroke: $gray2;
      }
    }
  }
}
