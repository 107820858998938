.cards-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 255px;
  height: 100%;
  transition: transform .2s ease;
  margin-right: 60px;
  margin-bottom: 60px;
  background: #F3F3F3;
  padding: 16px 12px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__title {
    text-align: center;
  }

  &__img {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    width: 255px;
    height: 255px;
    cursor: pointer;

    img {
      width: 170px;
      height: 255px;
    }
  }

  &__photos {
    background: none;
    width: 290px;
    height: 290px;
    transition: transform .2s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.025);
    }
  }

  &__videos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: transform .2s ease;
    width: 466px;
    background: #F3F3F3;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
    cursor: pointer;

    & .video-desc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & iframe {
      width: 426px;
      height: 240px;
      margin-bottom: 20px;
    }
  }

  &__link {
    @media (max-width: 580px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &:hover {
    transform: scale(1.025);
  }

  @media (max-width: 670px) {
    //width: auto;
    text-align: center;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
