.pdf-bookmarks-list__remove-button {
  position: absolute;
  top: -7px;
  right: -1px;
  width: 10px;
  height: 10px;

  button {
    width: 10px;
    height: 10px;
  }

  svg {
    transition: fill .2s;
  }

  &:hover {
    svg {
      fill: $gray6;
    }
  }
}