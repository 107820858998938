.sidebar__content {
  width: 0;
  height: 100vh;
  top: 0;
  left: auto;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px grey;
  position: absolute;
  z-index: 11;
  opacity: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  pointer-events: auto;


  &.enter {
    width: 256px;
    opacity: 1;
    right: 0;
  }
}
