.header-search__dropdown {
  position: absolute;
  z-index: 9999;
  width: 100%;
  left: 0;
  top: 42px;
  background: white;
  box-shadow: 0px 4px 12px rgba(15, 15, 15, 0.08);
  margin: 0;
  padding: 15px 0;
  border: 1px solid $gray2;
  border-top: none;

  @media (max-width: 480px) {
    top: 32px;
  }
}
