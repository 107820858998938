.text {
  font-weight: 500;
  color: #1A1A1A;
}

.text-grey {
  color: $gray7;
}

.text-grey-1 {
  color: $gray9;
}

.text-bold {
  font-weight: 700;
}

.text-with-bg {
  background: #7AA8EE;
  border-radius: 10px;
  color: #ffffff;
  width: fit-content;
  padding: 15px 60px;

  @media (max-width: 768px) {
    padding: 7px 30px;
  }

  &.year500 {
    padding: 3px 10px;
  }

  &.last {
    padding: 5px 13px;
    text-indent: 0;
  }
}

.text-blue {
  color: $blue;
}

.text-center {
  text-align: center;
}

.text-very-small {
  font-size: 0.8rem;
  line-height: 1rem;
}

.text-small {
  font-size: 1rem;
  line-height: 1.2rem;

  @media (max-width: 520px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.text-medium {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.text-indent {
  text-indent: 45px;
  @media (max-width: 768px) {
    text-indent: 0;
  }
}

.text-m-l-40 {
  margin-left: 60px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.text-m-r-100 {
  margin-right: 100px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.text-super-medium {
  font-size: 1.5rem;
  line-height: 1.9rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.text-large {
  font-size: 1.9rem;
  line-height: 2.2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.text-very-large {
  font-size: 2.5rem;
  line-height: 3rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

.text-caption {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: $gray7;
}

.text-logo {
  font-size: 1.4rem;
  line-height: 1.6rem;
  white-space: nowrap;
}

.text-link {
  color: $gray7;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -.1em;
    display: block;
    height: 1px;
    width: 0;
    background-color: $gray7;
    transition: all .2s;
  }

  &:hover:after {
    width: 100%;
  }
}

.footer__email-block, .nav-list {
  .text-link {
    color: $default-text-color;

    &:after {
      background-color: $default-text-color;
    }
  }
}

.comments-item, .header-search {
  .text-link {
    color: $blue;

    &:after {
      background-color: $blue;
    }
  }
}
