.desktop-version {
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile-version {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
