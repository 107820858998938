.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    background: #EDF4FE;
    box-shadow: 0px 4px 4px rgba(111, 168, 244, 0.35), inset 0px 4px 4px rgba(106, 143, 247, 0.1);
    margin-bottom: 26px;
    border-radius: 10px;
    padding: 16px 20px 16px;

    &:hover, &.active {
      background: #6FA8F4;
      color: #ffffff;
    }
  }

  &.archive {
    width: 464px;

    :nth-child(1) {
      width: 100%;
    }
  }
  &.photos {
    width: 520px;

    :nth-child(odd) {
      width: 300px;
    }
    :nth-child(even) {
      width: 160px;
    }
  }
}