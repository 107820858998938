.elem-page__info-block {
  display: flex;
  justify-content: center;
  padding: 0 90px;

  @media (max-width: 768px) {
    padding: 0 15px;
    justify-content: space-between;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
  }
}
