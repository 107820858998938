.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.textLayer > div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: #2078A9;
}

.textLayer .highlight.selected {
  background-color: #0094FF;
}

.textLayer .highlight.begin {
  border-radius: 4px 0px 0px 4px;
}

.textLayer .highlight.end {
  border-radius: 0px 4px 4px 0px;
}

.textLayer .highlight.middle {
  border-radius: 0px;
}

.textLayer ::selection { background: rgb(0,0,255); }
.textLayer ::-moz-selection { background: rgb(0,0,255); }

.textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

.textLayer .endOfContent.active {
  top: 0px;
}

.pdfViewer {
  height: 100%;
  padding-top: 22px;
  margin-left: 320px;
  transition: all 0.5s;
  background-color: $gray2;

  &.full {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    margin-left: 270px;
  }

  @media (max-width: 768px) {
    margin-left: 210px;
  }

  @media (max-width: 645px) {
    margin-left: 150px;
  }

  @media (max-width: 580px) {
    margin-left: 0;
    margin-top: 180px;

    &.full {
      margin-top: 0;
    }
  }
}

.pdf-pages-container {
  // overflow-x: hidden;
  // padding: 0 10px;
  // width: 100vw;
}

//.pdfViewer .canvasWrapper {
//  overflow-x: scroll;
//  width: 100% !important;
//
//  canvas {
//    overflow-x: scroll;
//  }
//}

//.pdfViewer .page {
//  overflow-x: scroll;
//  width: 100% !important;
//}
//
//.pdfViewer .textLayer {
//  overflow: scroll;
//  width: 100% !important;
//}

.pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: 5px auto 5px auto;
  position: relative;
  overflow: visible;
  background-clip: content-box;
  background-color: white;
}

.pdfViewer.removePageBorders .page {
  margin: 0px auto 10px auto;
  border: none;
}

.pdfViewer.singlePageView {
  display: inline-block;
}

.pdfViewer.singlePageView .page {
  margin: 0;
  border: none;
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}