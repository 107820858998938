.submit-form__link-item {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -.1em;
    display: block;
    height: 1px;
    width: 0;
    background-color: $default-text-color;
    transition: all .2s;
  }

  &:hover:after {
    width: 100%;
  }
}
