.header-search-result-item__img {
  width: 40px;
  height: 55px;
  margin-right: 15px;

  img {
    width: 40px;
    height: 55px;
  }
}
