.filters__fields {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;

  @media (max-width: 680px) {
    flex-direction: column;
    margin: 30px 0;
  }
}
