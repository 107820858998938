.footer__link-block {
  @media (max-width: 768px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 580px) {
  .footer__link-block {
    display: block;
    height: auto;
    .nav-list {
      display: none;
    }
  }
}
