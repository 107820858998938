#pdfSearchbar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  max-height: 36px;
  border-radius: 3px;
  background-color: #313b51;
  z-index: 9999;
  padding: 10px;
  bottom: 76px;
  transition: all 0.5s;
  margin-left: 58px;

  @media (max-width: 440px) {
    width: 250px;
    margin-left: 84px;
  }

  input {
    font-size: 0.813rem;
    background-color: #313b51;
    border: none;
    color: white;

    &::placeholder {
      color: rgba(151, 161, 182, 0.5);
    }

    &:focus {
      outline: none !important;
      border: none;
    }
  }

  .search-info {
    font-size: 0.813rem;
    display: flex;
    align-items: center;

    .search-status {
      display: flex;
      align-items: center;
      color: rgba(151, 161, 182, 0.5);
    }
    .search-bar-ico {
      cursor: pointer;
      margin-left: 4px;

      &.disabled {
        cursor: default;
        path {
          fill-opacity: 0.29;
        }
      }
    }
  }
}