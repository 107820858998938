.reader {
  height: 100%;
  //position: relative;
  background-color: #CED4DA;

  //.pdfViewer {
  //  padding: 0;
  //}

  .sdk-HeaderView-header {
    display: none;
  }
}
