.filters__dropdown {
  overflow: hidden;
  transition: height 0.3s ease-out, margin 0.4s ease;
  height: 0;
  display: flex;
  justify-content: center;

  form {
    width: 100%;
  }
}
