@import "./__link/articles-item__link.scss";
@import "./__title/articles-item__title.scss";
@import "./__button/articles-item__button.scss";

.articles-item {
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-color: $gray;
  transition: all .2s;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    transform: scale(1.02);
    background-color: #e8e8e8;
  }
}