.photo-modal {
  display: flex;
  flex-direction: column;
  position: relative;

  &__img {
    min-width: 440px;
    min-height: 440px;
    border-radius: 10px;
    margin-bottom: 40px;
  }

  &__text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__name-block {
    display: flex;
    flex-direction: column;
  }

  &__year {
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
  }

  &__name {
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  &__desc {
    font-weight: 400;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 980px) {
    width: auto;
  }
}
