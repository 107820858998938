.error-page__text {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-left: 3px;

  @media (max-width: 860px) {
    font-size: 1rem;
    margin-left: 0;
  }
}
