@import "./__content/error-page__content";
@import "./__text-block/error-page__text-block";
@import "./__title/error-page__title";
@import "./__subtitle/error-page__subtitle";
@import "./__text/error-page__text";

.error-page {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  padding: 20px;

  @media (max-width: 480px) {
    text-align: center;
  }
}

.error-page .wrap {
  height: 100%;
}
