.pdf-thumbnail-bar {
  @media (max-width: 580px) {
    display: flex;
    overflow-x: scroll;
  }
}

.thumbnail {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img, .placeholder {
    border: solid 3px white;
    width:100%;
    cursor: pointer;

    &:hover, &.focused {
      border: solid 3px #7c86a5;
    }
  }
  .page-number {
    margin-top: 4px;
  }

  @media (max-width: 580px) {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }

    img, .placeholder {
      width: 50px;
    }

    .page-number {
      font-size: 0.7rem;
    }
  }
}