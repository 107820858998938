.header__logo {
  font-family: 'Sholom', 'Arial', sans-serif;
  font-weight: 400;

  &:hover {
    .text-logo {
      text-decoration: underline;
    }
  }
}
