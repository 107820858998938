.main-page__menu-item {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 290px;
  width: 290px;
  transition: all .5s ease;
  margin-bottom: 60px;
  margin-left: 50px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    &.books {
      background: url('../../../../images/menu-bg-books-hover.png') center center no-repeat;
    }

    &.articles {
      background: url('../../../../images/menu-bg-articles-hover.png') center center no-repeat;
    }

    &.editions {
      background: url('../../../../images/menu-bg-editions-hover.png') center center no-repeat;
    }


    .main-page__menu-item-link {
      background: rgba(111, 168, 244, .4);
    }

    .main-page__menu-item-text {
      background-color: #ffffff;
      color: #000000;
    }
  }

  &.books {
    background: url('../../../../images/menu-bg-books.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.articles {
    background: url('../../../../images/menu-bg-articles.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.editions {
    background: url('../../../../images/menu-bg-editions.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.archive {
    background: url('../../../../images/menu-bg-archive.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.handwritings {
    background: url('../../../../images/menu-bg-handwritings.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.photos {
    background: url('../../../../images/menu-bg-photos.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.videos {
    background: url('../../../../images/menu-bg-videos.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  &.forum {
    background: url('../../../../images/menu-bg-forum.png') center center no-repeat;
    @media (max-width: 768px) {
      background: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1440px) {
    min-height: auto;
    width: 250px;
    height: 250px;
    &:not(:first-child) {
      margin-left: 50px;
    }
  }

  @media (max-width: 768px) {
    width: 40%;
    margin: 0 0 20px 0 ;
    min-height: 0;
    box-shadow: none;
    border-radius: 0;

    &:nth-child(even) {
      margin-left: 20px;
    }
  }
}

