.main-page__about-info {
  width: 40%;
  margin-right: 40px;
  padding: 20px;
  text-align: justify;

  background: #EDF4FE;
  box-shadow: 0px 4px 4px rgba(111, 168, 244, 0.35);
  border-radius: 10px;


  .text-very-large {
    margin-bottom: 20px;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}
