.checkbox {
  display: flex;
  align-items: center;
  input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;

    @media (max-width: 420px) {
      width: 8px;
      height: 8px;
    }
  }

  input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -4px;
    left: -4px;
    border: 1px solid $gray3;
    background: #ffffff;
    border-radius: 2px;

    @media (max-width: 420px) {
      width: 12px;
      height: 12px;
    }
  }

  input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -4px;
    left: -4px;
    border-color: transparent;
    background: $blue;

    @media (max-width: 420px) {
      width: 12px;
      height: 12px;
    }
  }

  input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 4px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: -2px;
    left: 2px;

    @media (max-width: 420px) {
      width: 3px;
      height: 8px;
      top: -3px;
      left: 1px;
    }
  }

  label {
    margin-left: 12px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    line-height: 0.8rem;

    @media (max-width: 420px) {
      font-size: 0.8rem;
      line-height: 0.6rem;
    }
  }
}
