@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI/PT-Root-UI_Light.woff2') format('woff2'),
  url('../fonts/PT-Root-UI/PT-Root-UI_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI/PT-Root-UI_Regular.woff2') format('woff2'),
  url('../fonts/PT-Root-UI/PT-Root-UI_Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI/PT-Root-UI_Medium.woff2') format('woff2'),
  url('../fonts/PT-Root-UI/PT-Root-UI_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/PT-Root-UI/PT-Root-UI_Bold.woff2') format('woff2'),
  url('../fonts/PT-Root-UI/PT-Root-UI_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sholom';
  src: url('../fonts/Sholom/Sholom-400.woff2') format('woff2'),
  url('../fonts/Sholom/Sholom-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu/Ubuntu-700.woff2') format('woff2'),
  url('../fonts/Ubuntu/Ubuntu-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
