.progress-bar {
  width: 100%;
  text-align: center;
  height: 4px;

  .progress {
    position: relative;
    color: #ffffff;
    width: 100%;
    background: rgb(124, 153, 209);
    border-radius: 0;
    text-align: center;
    z-index: 10000;

    .progress-percent{
      height: 4px;
      position: absolute;
      background: linear-gradient(270deg, #43bcfd 43.94%, #122afc 100%);
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 10001;
    }
  }
}