@import "__page/content__page.scss";

.content {
  min-height: calc(100vh - 160px - 230px);

  @media (max-width: 768px) {
    min-height: calc(100vh - 82px - 86px);
  }

  &.isFetching {
    display: grid;
    place-items: center;
  }
}

.content_reader {
  min-height: 0;
  height: calc(100vh - 160px);

  @media (max-width: 768px) {
    height: calc(100vh - 82px);
  }
}
