@import "./__item-container/pdf-bookmarks-list__item-container.scss";
@import "./__item/pdf-bookmarks-list__item.scss";
@import "./__no-found/pdf-bookmarks-list__no-found.scss";
@import "./__remove-button/pdf-bookmarks-list__remove-button.scss";

.pdf-bookmarks-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 10px 0 10px;
}