.pdf-bookmarks-list__item {
  background: #97a1b6;
  padding: 5px 15px;
  border-radius: 20px;
  color: #ececec;
  font-size: 0.9rem;
  transition: background-color .1s;

  &:hover {
    cursor: pointer;
    background: #929cb3;
  }
}