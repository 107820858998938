.elem-page__img-and-read-block {
  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      width: 255px;
    }
  }
}
