// margin-top
.m-t-8 {
  margin-top: 8px;
}
.m-t-60 {
  margin-top: 60px;
}

// margin-right
.m-r-10 {
  margin-right: 10px;
}
.m-r-20 {
  margin-right: 20px;
}

// margin-bottom
.m-b-3 {
  margin-bottom: 3px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
