@import "./__title/elem-list-page__title.scss";
@import "./__actions/elem-list-page__actions.scss";

.elem-list-page {
  padding: 65px 0;

  @media (max-width: 768px) {
    padding: 30px 0 65px;
  }
}
