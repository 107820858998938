.header-user {
  display: flex;
  align-items: center;
  margin: 0 40px 0 75px;

  @media (max-width: 1088px) {
    margin: 0 35px;
  }

  @media (max-width: 968px) {
    margin: 0 20px;
  }
}
