@import "./__toggle-button-container/filters__toggle-button-container.scss";
@import "./__toggle-button/filters__toggle-button.scss";
@import "./__dropdown/filters__dropdown.scss";
@import "./__fields/filters__fields.scss";
@import "./__form-item/filters__form-item.scss";
@import "./__years/filters__years.scss";

.filters {
  margin-bottom: 40px;
  transition: margin-bottom .4s;

  &.showDropdown {
    margin-bottom: 0;

    @media (max-width: 680px) {
      margin-bottom: 20px;
    }
  }
}
