.main-page__about-image {
  margin-right: 100px;
  margin-top: 30px;

  img {
    width: 600px;
  }

  @media (max-width: 1120px) {
    img {
      width: 340px;
      height: 380px;
    }
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-top: 200px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &.mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      margin: 20px 0 0;
      img {
        width: 290px;
        height: auto;
      }
    }
  }
}
