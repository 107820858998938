.footer__info-block {
  @media (max-width: 580px) {
    .privacy-policy {
      display: none;
    }
    .m-b-20 {
      margin-bottom: 5px;
    }
    .m-b-10 {
      margin: 0;
    }
  }
}
