@import "./__input-container/header-search__input-container.scss";
@import "./__dropdown/header-search__dropdown.scss";
@import "./__show-all/header-search__show-all.scss";

.header-search {
  display: flex;
  margin-left: 30px;
  width: 100%;

  .button {
    margin-left: 10px;
  }
}

.main-page {
  .header-search {
    @media (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 60px;
      display: flex;
    }

    @media (max-width: 480px) {
      margin-bottom: 40px;
    }
  }
}
