.elem-page__text-block {
  margin-right: 60px;
  flex: 0 0 60%;

  @media (max-width: 768px) {
    margin-right: 20px;
    flex: 0 0 50%;
  }

  @media (max-width: 580px) {
    margin-right: 0;
    flex: 0 1 auto;
    margin-bottom: 35px;
  }
}
