@import "./__info-block/elem-page__info-block.scss";
@import "./__title/elem-page__title.scss";
@import "./__subtitle/elem-page__subtitle.scss";
@import "./__statistics/elem-page__statistics.scss";
@import "./__comments-count/elem-page__comments-count.scss";
@import "./__text-block/elem-page__text-block.scss";
@import "./__img/elem-page__img.scss";
@import "./__img-and-read-block/elem-page__img-and-read-block.scss";

.elem-page {
  padding: 50px 0;

  @media (max-width: 580px) {
    padding: 30px 0 50px;
  }
}
