@import "./__container/header__container.scss";
@import "./__logo/header__logo.scss";
@import "./__burger/header__burger.scss";

.header {
  display: flex;
  align-items: center;
  padding: 29px 40px;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 30px;
  }
}
