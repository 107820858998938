.menu-burger__line {
  height: 2px;
  width: 14px;
  background-color: #000000;
  margin-bottom: 3px;
  transition: all .2s;

  &:last-of-type {
    margin-bottom: 0
  }
}

.menu-burger_active {
  .menu-burger__line {
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: calc(50% - 7px);
  }
  .menu-burger__line_middle {
    display: none;
  }
  .menu-burger__line_top {
    transform: rotate(45deg);
  }
  .menu-burger__line_bottom {
    transform: rotate(-45deg);
  }

  &:hover {
    .menu-burger__line_top {
      transform: rotate(45deg);
    }
    .menu-burger__line_bottom {
      transform: rotate(-45deg);
    }
  }
}
