.header-switch-lang {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  color: $gray3;
  display: flex;

  span {
    margin: 0 5px;
    cursor: default;
  }

  button {
    color: $gray3;
    transition: color .1s;

    &:hover {
      color: $default-text-color;
    }
  }

  .active {
    cursor: default;
    color: $default-text-color;
  }
}
