.sidebar__nav-list {
  display: flex;
  flex-direction: column;

  .text {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
