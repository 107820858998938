@import "./progress-bar.scss";
@import "./pdf-pages.scss";
@import "./pdf-toolbox.scss";
@import "./pdf-thumbnail-bar.scss";
@import "./pdf-search-bar.scss";
@import "./pdf-sidebar.scss";

#viewer-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  #viewer {
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: $gray2;
    padding: 50px 0;

    @media (max-width: 768px) {
      padding: 30px 0;
    }
  }

}