.main-page__menu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-bottom: 30px;
    -ms-flex-pack: distribute;
    justify-content: center;
    -webkit-justify-content: center;
  }
}