.error-text {
  font-size: .87rem;
  font-weight: 400;
  line-height: 1.2;
  color: $red;
  margin-top: 5px;
  display: none;
}

.input_invalid + .error-text {
  display: block;
  position: absolute;
}
