.articles-item__button {
  background-color: $dark-blue;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 1rem;
  color: #ffffff;
  transition: background-color .2s;

  &:hover {
    background-color: $blue;
  }
}