.main-page__menu-item-link {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transition: background-color .5s ease;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
}
