// black
$black: #1A1A1A;
$black1: #152536;

// gray
$gray: #eeeeee;
$gray1: #BDBDBD;
$gray2: #CED4DA;
$gray3: #ABB5BE;
$gray4: #C4C4C4;
$gray5: #4F4F4F;
$gray6: #454545;
$gray7: #949494;
$gray8: #F9F9F9;
$gray9: #ABB5BE;

// colored
$blue: #408FFF;
$dark-blue: #638eff;
$light-blue: #e3f1ff;
$green: #219653;
$red: #EB5757;
$light-red: #ffe3e3;

// other
$default-text-color: #1A1A1A;
