.elem-page__img {
  margin-bottom: 15px;
  border-radius: 20px;

  width: 255px;
  height: 325px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  @media (max-width: 580px) {
    width: 290px;
    height: 352px;
    border-radius: 15px;

    img {
      border-radius: 15px;
    }
  }
}
