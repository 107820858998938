.dropdown__menu {
  width: max-content;
  position: absolute;
  top: 35px;
  margin: 0;
  opacity: 0;
  padding: 10px 0;
  box-shadow: 0 0 13px -3px rgba(0, 0, 0, .1);
  z-index: 1000;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  overflow: hidden;
  transition: all .3s ease-in-out;
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }

  &.entering {
    opacity: 1;
    pointer-events: none;
  }

  &.entered {
    opacity: 1;
    pointer-events: auto;
  }

  &.exiting {
    opacity: 0;
    pointer-events: auto;
  }

  &.exited {
    opacity: 0;
    pointer-events: none;
  }
}

.header-user-menu {
  .dropdown__menu {
    top: 45px;
    right: 0;
  }
}
