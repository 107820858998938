.main-page__menu-item-text {
  padding: 6px 31px;
  background: #6FA8F4;
  border-radius: 10px;
  color: #ffffff;
  transition: background-color .5s ease, color .5s ease;

  font-size: 1.6rem;
  line-height: 2rem;

  @media (max-width: 768px) {
    padding: 5px 12px;
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;
    width: 100%;
    &:hover {
      background-color: $blue;
      color: #ffffff;
      cursor: pointer;
    }
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}
