.comments-item__submit-button {
  width: 35px;
  height: 35px;
  background: #408FFF;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: background-color .2s;
  position: absolute;
  bottom: 8px;
  right: 6px;

  &:hover {
    background-color: $dark-blue;
  }
}
