.dropdown__menu-item {
  width: 100%;
  background: transparent;
  display: flex;
  padding: 5px 15px;
  transition: all .1s ease-in-out;

  &:hover {
    background: #e1ebff;
  }
}

.header-user-menu {
  .dropdown__menu-item {
    justify-content: flex-end;
  }
}
