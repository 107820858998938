.nav-list {
  margin-bottom: 45px;
  a {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}
