.desc-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;

  &__title {
    margin-left: 16px;
  }

  &__title-line {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
  }

  &__hide-text {
    margin-left: auto;
  }

  &__dropdown {
    overflow: hidden;
    transition: height 0.3s ease-out, margin 0.4s ease;
    display: flex;
    justify-content: center;
  }


  &__text {
    padding: 50px 24px 50px;
    margin-bottom: 60px;
    background: #EDF4FE;
    box-shadow: 0 4px 4px rgba(111, 168, 244, 0.35);
    border-radius: 10px;
    transition: opacity 0.5s, display 0.5s;
  }

  &__left {
    width: 45%;
  }

  &__right {
    width: 45%;
    height: 460px;
    border-radius: 10px;


    &.books {
      background: url('../../../images/menu-bg-books.png') center center no-repeat;
      background-size: cover;
    }

    &.articles {
      background: url('../../../images/menu-bg-articles.png') center center no-repeat;
      background-size: cover;
    }

    &.periodicals {
      background: url('../../../images/menu-bg-editions.png') center center no-repeat;
      background-size: cover;
    }

    &.archive {
      background: url('../../../images/menu-bg-archive.png') center center no-repeat;
      background-size: cover;
    }

    &.handwritings {
      background: url('../../../images/menu-bg-handwritings.png') center center no-repeat;
      background-size: cover;
    }

    &.photos {
      background: url('../../../images/menu-bg-photos.png') center center no-repeat;
      background-size: cover;
    }

    &.videos {
      background: url('../../../images/menu-bg-videos.png') center center no-repeat;
      background-size: cover;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    &__right {
      width: 100%;
      margin-bottom: 50px;
      height: 300px;
    }
    &__left {
      width: 100%;
    }
    &__dropdown {
      height: 0;
    }
  }
}