.filters__years {
  display: flex;
  align-items: center;

  .input {
    input {
      padding: 12px 15px;
    }
  }
}
