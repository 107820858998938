.scroll-top-btn {
  position: fixed;
  right: 45px;
  bottom: 30px;

  svg {
    transition: fill .2s ease;
    fill: $gray4;
  }

  &:hover {
    svg {
      fill: $black;
    }
  }

  &.articlesPage {
    svg {
      fill: $black;
    }
  }

  @media (max-width: 670px) {
    width: 30px;
    height: 30px;
    right: 15px;
    bottom: 15px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.scroll-top-btn_all-elem {
  bottom: 0;
}
