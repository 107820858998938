@import "./__menu-list/main-page__menu-list.scss";
@import "./__menu-item/main-page__menu-item.scss";
@import "./__menu-item-link/main-page__menu-item-link.scss";
@import "./__menu-item-text/main-page__menu-item-text.scss";
@import "./__about/main-page__about.scss";
@import "./__about-info/main-page__about-info.scss";
@import "./__about-image/main-page__about-image.scss";
@import "./__forum-button/main-page__forum-button.scss";

.main-page {
  padding: 65px 0 80px;

  @media (max-width: 768px) {
    padding: 35px 0 50px;
  }
}

.main-page {
  .footer__email-block {
    margin-top: 35px;
  }
}
