@import './__line/menu-burger__line.scss';

.menu-burger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 9000;

  &:active {
    background: none;
    outline: none;
    -webkit-tap-highlight-color: rgba($color: #fff, $alpha: 0);
  }
}
