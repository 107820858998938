@import "./__title/submit-form__title.scss";
@import "./__fields/submit-form__fields.scss";
@import "./__item/submit-form__item.scss";
@import "./__item-label/submit-form__item-label.scss";
@import "./__link-list/submit-form__link-list.scss";
@import "./__link-item/submit-form__link-item.scss";
@import "./__close-button/submit-form__close-button.scss";
@import "./__condition/submit-form__condition.scss";

.submit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  width: 520px;
  position: relative;

  @media (max-width: 580px) {
    width: auto;
  }

  @media (max-width: 520px) {
    padding: 30px 40px;
  }
}

@media (max-width: 580px) {
  .ReactModal__Content {
    width: 90%;
  }
}
