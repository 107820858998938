@import "./__logo/copyright__img.scss";

.copyright {
  display: flex;
  align-items: center;

  p {
    margin-right: 15px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: flex-end;

    p {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}
