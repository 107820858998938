// top
.p-t-25 {
  padding-top: 25px;
}
.p-t-65 {
  padding-top: 65px;
}

// top-bottom
.p-t-b-40 {
  padding: 40px 0;
}

// bottom
.p-b-120 {
  padding-bottom: 120px;
}

// left-right
.p-l-r-80-90 {
  padding-left: 80px;
  padding-right: 90px;
}
.p-l-r-40-55 {
  padding-left: 40px;
  padding-right: 55px;
}
